import React, { useEffect, useState } from 'react';
// import LeftSidebar from '../Sidebar/LeftSideBar';
import { Typography } from '@mui/material';
// import { Doughnut, Pie } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, Title, Tooltip, Legend, PolarAreaController, RadialLinearScale, ArcElement } from 'chart.js';
// import GraphModal from './GraphModal/GraphModal';
// import OtherComponent from './OtherComponent';
import { PolarArea } from 'react-chartjs-2';
import SubstationDoghtnut from './SubstationDoghtnut';
import ToggleSwitch from './ToggleSwitch/ToggleSwitch';


// const url = 'https://api-reports.fornaxenergytech.com';
// const url = 'http://localhost:5000';
const url = process.env.REACT_APP_API_URL;


// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, PolarAreaController, RadialLinearScale, ArcElement);

const SubstationView = () => {
  const [pointTypeData, setPointTypeData] = useState([]);
  const [organizationData, setOrganizationData] = useState();
  const [organizationId, setOrganizationId] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [selectedData, setSelectedData] = useState({ name: null, type: null });

  const [isOn, setIsOn] = useState(false);


  const [isConsumerLoad, setIsConsumerLoad] = useState(false);
  const [isSolarMeter, setIsSolarMeter] = useState(true);

  const [selectedName, setSelectedName] = useState();

  const [filterProjectIds, setFilterProjectIds] = useState([]);
const[project,setProjects] = useState([]);

const [refresh, setRefresh] = useState(false);

  const [data1, setData1] = useState([]);

  // Fetch the data
  useEffect(() => {
    axios.get(`${url}/api/OrganizationView/Data`)
      .then((result) => {
        // console.log("newData", result.data);
        setData1(result.data);
        // setFilteredData(result.data);
        setFilteredData(result.data);
      }).catch((e) => {
        console.log("Error for Consumer Details :", e);
      });


  }, []);

  useEffect(() => {
    console.error("Awesome data", organizationId);
    console.error("Filter data", data1);

    // if(!selectedData || selectedData.length < 0){

    //   setFilteredData(data1);
    // }

    if (data1.length > 0 && organizationId.length > 0) {
      // Filter the data based on the array of organizationId
      const filtered = data1.filter(item =>
        organizationId.some(id => item.owner_id.includes(id))
      );
      console.log(organizationId);

      console.error("Awesome filter data", filtered);
      if (filtered.length > 0) {
        setFilteredData(filtered);

      } else {
        setFilteredData([]);
      }
      // Update the state with filtered data
    } else {
      // Clear the filteredData if conditions are not met
      setFilteredData(data1);
    }
  }, [data1, organizationId, selectedData]);

  //   // const[lengthData, length]
  const [consumerDetail, setConsumerDetail] = useState([]);

  useEffect(() => {

    axios.get(`${url}/api/OrganizationView/ConsumersData`)
      .then((result) => {
        console.error("newData21", result.data);
        setConsumerDetail(result.data);
        // setData(result.data);
        // setFilteredData(result.data);
      }).catch((e) => {
        console.log("Error for Consumer Details :", e);
      });


  }, []);




  // Process the filtered data for point types
  useEffect(() => {
    if (filteredData.length === 0) {
      setPointTypeData([]);
    }
    if (filteredData.length > 0) {
      const countPointTypes = (data) => {
        const result = data.reduce((acc, item) => {
          const pointType = item.point_type;

          if (!acc[pointType]) {
            acc[pointType] = {
              pointtype: pointType,
              size: 0,
              xmr_capacities: {}
            };
          }

          acc[pointType].size += 1;

          if (item.point_props) {
            const pointProps = JSON.parse(item.point_props);
            if (pointProps.xmr_capacity !== undefined) {
              const capacity = pointProps.xmr_capacity;
              if (!acc[pointType].xmr_capacities[capacity]) {
                acc[pointType].xmr_capacities[capacity] = 0;
              }
              acc[pointType].xmr_capacities[capacity] += 1;
            }
          }

          return acc;
        }, {});

        // Convert xmr_capacities to an array of objects
        return Object.values(result).map(item => ({
          pointtype: item.pointtype === "Gentry" ? "Feeders" : item.pointtype,
          size: item.size,
          xmr_capacities: Object.entries(item.xmr_capacities).map(([capacity, count]) => ({
            [capacity]: count
          }))
        })).sort((a, b) => a.pointtype === "Feeders" ? -1 : b.pointtype === "Feeders" ? 1 : 0);
      };



      // Function to get unique positions from line_props
      const getUniquePositions = () => {
        return [
          ...new Set(
            filteredData.map(item => {
              try {
                const lineProps = JSON.parse(item.line_props);
                return lineProps?.position || null;
              } catch (e) {
                console.warn('Error parsing line_props:', e);
                return null;
              }
            }).filter(position => position !== null)
          )
        ];
      };

      // Function to calculate the length based on unique positions
      const calculateLength = (positions) => {
        return positions.map(position => {
          const lengthData = filteredData.filter(data => {
            try {
              const lineProps = JSON.parse(data.line_props);
              return lineProps?.position === position;
            } catch (e) {
              return false;
            }
          });
          const totalLength = lengthData.reduce((sum, data) => sum + (data.length || 0), 0);
          return { [position]: (totalLength / 1000).toFixed(2) }; // Convert to KM
        });
      };

      // Handle selectedName logic and graph data updates
      if (selectedName === "Consumers (NOS)" && isConsumerLoad) {
        setSelectedName("Load (KW)");
      } else if (selectedName === "Load (KW)" && !isConsumerLoad) {
        setSelectedName("Consumers (NOS)");
      } else if (selectedName === "PV (KW)" && isSolarMeter) {
        setSelectedName("PV Cons (NOS)");
      } else if (selectedName === "PV Cons (NOS)" && !isSolarMeter) {
        setSelectedName("PV (KW)");
      }

      // Process graph data based on pointTypeData and selectedName
      const processGraphData = (pointTypeData) => {
        const filteredGraphData = pointTypeData.find(item => item.pointtype === selectedName);
        if (filteredGraphData && filteredGraphData.xmr_capacities) {
          setGraphData(getChartData(filteredGraphData.xmr_capacities));
        } else if (!selectedName) {
          const fallbackGraphData = pointTypeData.find(item => item.pointtype === "Transformer");
          if (fallbackGraphData && fallbackGraphData.xmr_capacities) {
            setGraphData(getChartData(fallbackGraphData.xmr_capacities));
            setSelectedName("Transformer");
          }
        } else {
          setGraphData(getChartData([]));
        }
      };


      const pointTypeCounts = countPointTypes(filteredData);

      const filteredPointTypeCounts = pointTypeCounts.filter(item =>
        item.pointtype !== "Existing Pole(Double Circuit)" &&
        item.pointtype !== "HT Pole" &&
        item.pointtype !== "HT Route Point"
      );

      // Find the RMU, RMU with TC, and Transformer entries
      const rmu = filteredPointTypeCounts.find(item => item.pointtype === "RMU");
      const rmuWithTC = filteredPointTypeCounts.find(item => item.pointtype === "RMU with TC");
      const transformer = filteredPointTypeCounts.find(item => item.pointtype === "Transformer");


      if (rmuWithTC) {
        if (rmu) {
          rmu.size += rmuWithTC.size;  // Merge sizes
          rmu.xmr_capacities = [...rmu.xmr_capacities, ...rmuWithTC.xmr_capacities];  // Merge xmr_capacities arrays
        }
        if (transformer) {
          transformer.size += rmuWithTC.size;  // Merge sizes
          transformer.xmr_capacities = [...transformer.xmr_capacities, ...rmuWithTC.xmr_capacities];  // Merge xmr_capacities arrays
        }

        // Optionally remove the RMU entry after merging
        const rmuIndex = filteredPointTypeCounts.indexOf(rmuWithTC);
        if (rmuIndex !== -1) {
          filteredPointTypeCounts.splice(rmuIndex, 1);
        }
      }


      // Get length data
      const uniquePositions = getUniquePositions();
      const xmr_capacity = calculateLength(uniquePositions);

      // console.log(filteredPointTypeCounts);

      // // Extract unique project IDs
      // const filterProjectId = filteredData.length > 0
      // ? [...new Set(filteredData.map(item => item.project_id))]
      // : null;

      // console.log("filterprojectids",filterProjectId);



      // Add length data to point type counts
      const lengthData = {
        pointtype: "Length (KM)",
        size: (filteredData.reduce((sum, data) => sum + (data.length || 0), 0) / 1000).toFixed(2),
        xmr_capacities: xmr_capacity
      };

      // Filter by project ID and prepare final point type data
      const filterProjectId = filteredData.length > 0
        ? [...new Set(filteredData.map(item => item.project_id))]
        : null;
      setFilterProjectIds(filterProjectId);

            const filterFeeder = filterProjectId ? filterFeederByProjectId(filterProjectId) : [];


            console.log(project);
            console.log(filterProjectId);
            console.log(filterFeeder);

      const consumerLoad = filterProjectId ? filterLoadByProjectId(filterProjectId, consumerDetail) : [];
      const consumer = filterProjectId ? filterConsumersByProjectId(filterProjectId, consumerDetail) : [];
      const solarMeter = filterProjectId ? filterSolarMeterByProjectId(filterProjectId, consumerDetail) : [];
      const solarLoad = filterProjectId ? filterSolarLoadByProjectId(filterProjectId, consumerDetail) : [];

      // Final point type counts including length and consumer data
      let finalPointTypeCounts = [...filteredPointTypeCounts, lengthData];

      if (isConsumerLoad && isSolarMeter) {
        finalPointTypeCounts.push(consumerLoad, solarMeter);
      } else if (!isConsumerLoad && isSolarMeter) {
        finalPointTypeCounts.push(consumer, solarMeter);
      } else if (isConsumerLoad && !isSolarMeter) {
        finalPointTypeCounts.push(consumerLoad, solarLoad);
      } else {
        finalPointTypeCounts.push(consumer, solarLoad);
      }

   

// Separate feeders and non-feeders
const getFeeder = finalPointTypeCounts.filter(item => item.pointtype === 'Feeders');
const newFinalPointtypecount = finalPointTypeCounts.filter(item => item.pointtype !== 'Feeders');


// Update the xmr_capacity for feeders
const updatedFeeders = getFeeder.map(feeder => {
  const updatedCapacity = filterFeederByProjectId([feeder.project_id]) || {};

  // Remove one item from xmr_capacity if it exists
  const modifiedCapacity = { ...updatedCapacity };
  const keys = Object.keys(modifiedCapacity);
  
  if (keys.length > 0) {
    const firstKey = keys[0];
    if (modifiedCapacity[firstKey] > 0) {
      modifiedCapacity[firstKey] -= 1; // Decrement the first category
    }
  }

  return {
    ...feeder,
    xmr_capacities: filterFeeder
  };
});

console.log(updatedFeeders);

// Combine updated feeders with non-feeders
const finalPointtype = [...newFinalPointtypecount, ...updatedFeeders];

      console.log("pointtypeData", finalPointtype);
      // const[ ...rest]

         // Set point type data and process graph data
         setPointTypeData(finalPointTypeCounts);
         processGraphData(finalPointtype);
   

      setPointTypeData(finalPointtype);
    }
  }, [filteredData, isConsumerLoad, isSolarMeter, selectedName,refresh]);



  const filterFeederByProjectId = (filterProjectId) => {
    if (!project || typeof project !== 'object') {
      console.error("Invalid Feeder Detail:", project);
      return null;
    }
  
    const filteredProjects = project.filter(item => filterProjectId.includes(item.project_id));

 // Count occurrences by category
 const countByCategory = filteredProjects.reduce((acc, item) => {
  const category = item.category; // Replace 'category' with the actual property you want to count by
  acc[category] = (acc[category] || 0) + 1; // Adjust if necessary
  return acc;
}, {});

// Transform the countByCategory object into the desired output format
const result = Object.entries(countByCategory).map(([key, value], index) => ({
  [key]: value
}));

return result;
};
  // };
  
  

  const filterConsumersByProjectId = (projectids, consumerData) => {
    if (!consumerData || typeof consumerData !== 'object') {
      console.error("Invalid consumerData:", consumerData);
      return null;
    }

    let totalSize = 0;
    const totalXmrCapacities = {};

    projectids.forEach(projectid => {
      const filteredConsumers = consumerData[projectid];

      if (!filteredConsumers) {
        console.warn(`Project ID ${projectid} not found in consumerData`);
        return; // Skip this project ID if not found
      }

      // Accumulate the size
      totalSize += filteredConsumers.reduce((sum, item) => sum + item.consumers, 0);

      // Accumulate the xmr_capacities
      filteredConsumers.forEach(item => {
        if (!totalXmrCapacities[item.category]) {
          totalXmrCapacities[item.category] = 0;
        }
        totalXmrCapacities[item.category] += item.consumers;
      });
    });

    // Convert the totalXmrCapacities object to an array of objects
    const xmrCapacitiesArray = Object.entries(totalXmrCapacities).map(([category, consumer]) => ({
      [category]: consumer
    }));

    return {
      pointtype: "Consumers (NOS)",
      size: totalSize || 0,
      xmr_capacities: xmrCapacitiesArray || []
    };
  };



  const filterLoadByProjectId = (projectids, consumerData) => {
    if (!consumerData || typeof consumerData !== 'object') {
      console.error("Invalid consumerData:", consumerData);
      return null;
    }

    let totalSize = 0;
    const totalXmrCapacities = {};

    projectids.forEach(projectid => {
      const filteredConsumers = consumerData[projectid];

      if (!filteredConsumers) {
        console.warn(`Project ID ${projectid} not found in consumerData`);
        return; // Skip this project ID if not found
      }

      // Accumulate the size
      totalSize += filteredConsumers.reduce((sum, item) => sum + item.load, 0);

      // Accumulate the xmr_capacities
      filteredConsumers.forEach(item => {
        if (!totalXmrCapacities[item.category]) {
          totalXmrCapacities[item.category] = 0;
        }
        totalXmrCapacities[item.category] += parseInt(item.load);
      });
    });

    // Convert the totalXmrCapacities object to an array of objects
    const xmrCapacitiesArray = Object.entries(totalXmrCapacities).map(([category, consumer]) => ({
      [category]: consumer
    }));

    return {
      pointtype: "Load (KW)",
      size: parseInt(totalSize) || 0,
      xmr_capacities: xmrCapacitiesArray || []
    };
  };



  const filterSolarMeterByProjectId = (projectids, consumerData) => {
    if (!consumerData || typeof consumerData !== 'object') {
      console.error("Invalid consumerData:", consumerData);
      return null;
    }

    let totalSize = 0;
    const totalXmrCapacities = {};

    projectids.forEach(projectid => {
      const filteredConsumers = consumerData[projectid];

      if (!filteredConsumers) {
        console.warn(`Project ID ${projectid} not found in consumerData`);
        return; // Skip this project ID if not found
      }

      // Accumulate the size
      totalSize += filteredConsumers.reduce((sum, item) => sum + item.consumers, 0);

      // Accumulate the xmr_capacities
      filteredConsumers.forEach(item => {
        if (!totalXmrCapacities[item.category]) {
          totalXmrCapacities[item.category] = 0;
        }
        totalXmrCapacities[item.category] += item.consumers;
      });
    });

    // Convert the totalXmrCapacities object to an array of objects
    const xmrCapacitiesArray = Object.entries(totalXmrCapacities).map(([category, consumer]) => {
      // Ensure consumer is a number before formatting
      const formattedConsumer = parseInt(consumer)
      return {
        [category]: formattedConsumer
      };
    });
    return {
      pointtype: "PV Cons (NOS)",
      size: totalSize || 0,
      xmr_capacities: xmrCapacitiesArray || []
    };
  };



  const filterSolarLoadByProjectId = (projectids, consumerData) => {
    if (!consumerData || typeof consumerData !== 'object') {
      console.error("Invalid consumerData:", consumerData);
      return null;
    }

    let totalSize = 0;
    const totalXmrCapacities = {};

    projectids.forEach(projectid => {
      const filteredConsumers = consumerData[projectid];

      if (!filteredConsumers) {
        console.warn(`Project ID ${projectid} not found in consumerData`);
        return; // Skip this project ID if not found
      }

      // Accumulate the size
      totalSize += filteredConsumers.reduce((sum, item) => sum + item.solar_load, 0);

      // Accumulate the xmr_capacities
      filteredConsumers.forEach(item => {
        if (!totalXmrCapacities[item.category]) {
          totalXmrCapacities[item.category] = 0;
        }
        totalXmrCapacities[item.category] += item.solar_load;
      });
    });

    // Convert the totalXmrCapacities object to an array of objects
    const xmrCapacitiesArray = Object.entries(totalXmrCapacities).map(([category, consumer]) => ({
      [category]: parseInt(consumer)
    }));

    return {
      pointtype: "PV (KW)",
      size: parseInt(totalSize) || 0,
      xmr_capacities: xmrCapacitiesArray || []
    };
  };



  // Prepare chart data based on fetched data
  const getChartData = (capacities) => {
    const labels = [];
    const values = [];
    const colors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)'];

    capacities.forEach((capacityObj) => {
      const [key, value] = Object.entries(capacityObj)[0];
      labels.push(key);
      values.push(value);
      // Generate a color for each data point
      colors.push(`rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`);
    });

    return {
      labels,
      datasets: [
        {
          label: 'XMR Capacities',
          data: values,
          backgroundColor: colors,
          borderColor: colors.map(color => color.replace('0.2', '1')), // Use same color but with full opacity for border
          borderWidth: 1,

        },
      ],
    };
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        left: 50, // Adjust this value to create space between the graph and the legend
      },
      justifyContent: 'space-between'
    },
    plugins: {
      legend: {
        paddingLeft: '50px',
        position: 'right',
        align: 'center'
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    // Fetch data from API
    axios.get(`${url}/api/organization/Data`)
      .then((response) => {
        // Process and set the data
        const fetchedData = response.data;
        const labels = Object.keys(fetchedData);
        const values = Object.values(fetchedData);

        setOrganizationData({
          labels,
          datasets: [
            {
              label: 'Organization Types',
              data: values,
              backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)'],
              borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
              borderWidth: 1,
            },
          ],
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // // Chart options
  // const options1 = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (context) {
  //           return `${context.label}: ${context.raw}`;
  //         },
  //       },
  //     },
  //   },
  // };
  console.warn("Organization iD data-> ", organizationId)


  const handleClick = (capacities, pointtype) => {
    setGraphData(getChartData(capacities));
    setSelectedName(pointtype);
   
  };

  // console.log('Organization Data', organizationData);
  console.warn('Organization Data', organizationId);

  // useEffect(() => {
  //     // Find the first item with pointtype "Transformer"
  //     const transformerData = pointTypeData.find(point => point.pointtype === "Transformer");
  //     if (transformerData) {
  //         handleClick(transformerData.xmr_capacities, transformerData.pointtype);
  //     }
  // }, [pointTypeData]); // Depend on pointTypeData to trigger when it changes



  const handleRefresh = () => {
    if (data1) {  // Check if data is available
      setFilteredData(data1);
      setSelectedData({ name: null, label: null });
      setRefresh(!refresh);

    } else {
      console.error("Data is not available yet.");
    }
  };









  return (
    <>
      <div className="w-100 dashboard-body" style={{ height: '100%', maxHeight: '100%' }}>
        {/* <LeftSidebar /> */}
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '6px', height: '50px', background: 'white' }} className="border">
          <Typography variant="h6" gutterBottom sx={{ padding: '10px' }}>
            Substation View
          </Typography>
        </div>
        <div className='' style={{ overflowY: 'scroll', height: '100vh' }} >

          {/* <div className="head  m-3" style={{ fontFamily: 'Montserrat, sans-serif',  textAlign:'left' }}><h4>Data View</h4></div> */}
          <div className="bg-white m-2 shadow  rounded">
            <div style={{ overflowX: 'auto', borderRadius: '10px' }}>
              <table className="table table-bordered"  >

                {pointTypeData.length > 0 ? (


                  <tbody>
                    <tr>
                      <th>Object Type</th>
                      {pointTypeData.map((item, index) => (
                        <th key={index}>{item.pointtype}</th>
                      ))}
                    </tr>
                    <tr>
                      <td>Numbers</td>
                      {pointTypeData.map((item, index) => (
                        <td key={index}>{item.size}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Data View</td>
                      {pointTypeData.map((item, index) => (
                        <td key={index}>

                          {item.xmr_capacities.length > 0 ? <button className="btn btn-primary" onClick={() => handleClick(item.xmr_capacities, item.pointtype)}>View </button> : <button className="btn btn-secondary disabled" onClick={() => handleClick(item.xmr_capacities, item.pointtype)}>View </button>}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                ) : (<div className="d-flex justify-content-center align-items-center w-100" style={{ width: "100vw", height: '150px' }}><h5 className="">No Data  Found</h5></div>)}

              </table>


              {/* : <h1>No Data Found</h1>} */}
            </div>
          </div>




          <div className=" d-flex flex-row-reverse justify-content-between " >

            {/* <GraphModal data={getChartData(item.xmr_capacities)} options={options} /> */}

            <div className="bg-white w-50 shadow rounded">
              {pointTypeData.length > 0 && graphData ? (
                <div style={{ height: '100%', width: '100%' }}>
                  {/* <h3>{selectedName}</h3> */}
                  <div className="d-flex justify-content-between m-3">
                    <h3>{selectedName}</h3>
                    {(selectedName === "Consumers (NOS)" || selectedName === "Load (KW)") && (
                      <ToggleSwitch isOn={isConsumerLoad} setIsOn={setIsConsumerLoad} data={["Consumers (NOS)", "Load (KW)"]} />
                    )}

                    {(selectedName === "PV Cons (NOS)" || selectedName === "PV (KW)") && (
                      <ToggleSwitch isOn={isSolarMeter} setIsOn={setIsSolarMeter} data={["PV (KW)", "PV Cons (NOS)"]} />
                    )}

                  </div>

                  <div className='d-flex justify-content-center' style={{ height: '600px', paddingTop: '50px' }}>

                    <PolarArea data={graphData} options={options} /> </div>

                </div>
              ) : (<div className="d-flex justify-content-center align-items-center" style={{ height: '600px' }} ><h5 className="">No Data  Found</h5></div>)}
            </div>
            <div className='bg-white w-50 mx-2 p-2 shadow rounded d-flex justify-content-center'>

              <SubstationDoghtnut project={project} setProjects={setProjects} setOrganizationId={setOrganizationId} organizationId={organizationId} handleRefresh={handleRefresh} selectedData={selectedData} setSelectedData={setSelectedData} refresh={refresh} />

            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default SubstationView;
