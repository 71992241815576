import React, { useState } from 'react';
import TabbedViewer from '../TabbedViewer'; // Assuming this is your Treemap component
import './TabbedTableDiagram.css';

const ConsumerTabbedTable = ({ data }) => {
  const [activeTab, setActiveTab] = useState('table');

  // Get object keys for table headers
  const object = data.length > 0 ? Object.keys(data[0]) : [];
  // console.log(data);

  return (
    <div className="tab-container">
      <div className="tabs">
        <button className={activeTab === 'table' ? 'active' : ''} onClick={() => setActiveTab('table')}>
          Table
        </button>
      </div>
      <div className="content">
        {activeTab === 'table' && (
          <div className="horizontal-table">
            <div className="table-row header-row">
              {object.map((key, index) => (
                <div className="table-cell header" key={index}>{key.charAt(0).toUpperCase() + key.slice(1)}</div>
              ))}
            </div>
            {data.map((item, rowIndex) => (
              <div className="table-row" key={rowIndex}>
                {object.map((key, colIndex) => (
                  <div className="table-cell" key={colIndex}>{item[key]}</div>
                ))}
              </div>
            ))}
          </div>
        )}
      
      </div>
    </div>
  );
};

export default ConsumerTabbedTable;
