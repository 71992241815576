import React, { useState, useEffect } from 'react';
import {
  MaterialReactTable,
} from 'material-react-table';
import * as XLSX from 'xlsx';
import { Button, TextField, Container, Typography, Paper, LinearProgress } from '@mui/material';
import LeftSidebar from '../Sidebar/LeftSideBar';
import axios from 'axios';
import { Category, Filter, FilterDrama, TableView } from '@mui/icons-material';
import FilterModal from './FilterModal';
import TabbedViewer from './TabbedViewer';
import DraggableModal from './modal/DraggableModal';
import TabbedTableDiagram from './Tab/TabbedTableDiagram';
import OrganizationView from './OrganizationView';
import { ContentCopy } from '@mui/icons-material';
import TopNavbar from './TopNavbar';
import { blue } from '@mui/material/colors';
import SubstationView from './SubstationView';
import Spinner from './Spinner'; 
import Home from './Home';
import ExtraComponent from './ExtraComponent';
import ConsumerTabbedTable from './Tab/ConsumerTabbedTable';
// const url = 'https://api-reports.fornaxenergytech.com';
// const url = 'http://localhost:5000';
const url =  process.env.REACT_APP_API_URL;


const MainComponent = ({ authDetails}) => {


  const[menuSection,setMenuSection] = useState(null);
  const [subMenuSection, setSubMenuSection] = useState('dashboard');

  const [reportData, setReportData] = useState([]);
  const [networkDetail, setNetworkDetail] = useState([]);
  const [transformerDetail, setTransformerDetail] = useState([]);
  const [lineDetail, setLineDetail] = useState([]);
  // const[loading,setLoading] = useState(false);


  const[organization,setOrganization] = useState([]);

  const[error,setError] = useState(false);





  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/MainCompnent/Data`);
        setOrganization(response.data || []);

        // console.log("Organization is", response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    // Call the async function
    fetchData();
  }, [])





  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/MainComponent/ReportData`);
        setReportData(response.data || []);

        // console.error("Data is", response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    // Call the async function
    fetchData();
  }, [])

  const[consumerData,setConsumerData] = useState([]);
  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/MainComponent/ConsumerData`);
        setConsumerData(response.data || []);

        // console.log("Data is", response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    // Call the async function
    fetchData();
  }, [])



  function addOrganizationDetails(dataArray, organizationData) {
    return dataArray.map((data) => {
      const organizationPrefix = data.owner_id.substring(0, 3);
      const circlePrefix = data.owner_id.substring(3, 6);
      const divisionPrefix = data.owner_id.substring(6, 7);
      const subDivisionPrefix = data.owner_id.substring(7, 10);
  
      const organizationdata = organizationData.corporate.find(
        (org) => org.organization_id.startsWith(organizationPrefix)
      );
      const circleOrganization = organizationData.circle.find(
        (circle) => circle.organization_id.startsWith(organizationPrefix + circlePrefix)
      );
  
      const divisionOrganization = organizationData.division.find(
        (division) => division.organization_id.startsWith(organizationPrefix + circlePrefix + divisionPrefix)
      );
  
      const subDivisionOrganization = organizationData.subdivision.find(
        (subDivision) => subDivision.organization_id.startsWith(organizationPrefix + circlePrefix + divisionPrefix + subDivisionPrefix)
      );
  
      return {
        ...data,
        company: organizationdata ? organizationdata.organization_name : null,
        circle: circleOrganization ? circleOrganization.organization_name : null,
        division: divisionOrganization ? divisionOrganization.organization_name : null,
        subDivision: subDivisionOrganization ? subDivisionOrganization.organization_name : null,
      };
    });
  }
  
  const[updateData,setUpdateData] = useState([]);
  
  useEffect(()=>{
if(reportData.length > 0){
  const updatedData = addOrganizationDetails(reportData, organization);
  setUpdateData(updatedData);
  // console.log("updatew data details",updatedData);


}



  },[reportData])
  
//   const columns1 = ['ss_id', 'ss_name', 'ss_voltage', 'project_id', 'project_name', 'system_kv', 'category'];
//   const columns2 = ['xmr_capacity', 'xmr_core_type'];
//   const columns3 = ['position', 'type', 'length'];



  const columns1 = ["Location ID","Substation ID","Substation Name","SS KVA","Feeder Code","Feeder Name","Voltage","Category",];
  const columns2 = ["Capacity - KVA","Core Type"];
  const columns3 = ["Position","Line Type", "Length (M)"];
  const columns4 = ['Company','Circle','Division','SubDivision'] 
  const columns5 = ["Consumer (NOS)","Load (KW)","PV Cons (NOS)", "PV (KW)"];

  const allcolumns = [columns4,columns1, columns2, columns3,columns5];

useEffect(()=>{

  const data = reportData.filter(item => item.solar_load !=  0);

  // console.error("Solar_load Data",updateData);
},[])

  const mergedData = updateData.map(detail => {
    // const address = employeeAddress.find(addr => addr.id === detail.id) || {};
    // const education = employeeEducation.find(edu => edu.id === detail.id) || {};
    // const experience = employeeExperience.find(exp => exp.id === detail.id) || {};

    return {
      "Location ID":Number(detail.id),
      "Feeder Code": Number(detail.project_id),
      "Feeder Name": detail.project_name,
      "SS KVA": Number(detail.ss_voltage),
      "Voltage": Number(detail.system_kv),
      "Substation ID": Number(detail.ss_id),
      "Capacity - KVA": Number(detail.xmr_capacity),
      "Substation Name": detail.ss_name,
      "Category": detail.category,
      "Core Type": detail.xmr_core_type,
      "Position": detail.position,
      "Line Type": detail.type,
      "Length (M)":Number(parseFloat(detail.length).toFixed(2)),
      "Company":detail.company,
      "Circle":detail.circle,
      "Division":detail.division,
      "SubDivision":detail.subDivision,
      "PV (KW)":detail.solar_load,
      "PV Cons (NOS)":detail.solar_meters,
      "Load (KW)":detail.consumer_load,
      "Consumer (NOS)":detail.consumers





    };
  });

  // console.log("mERGED dATA",reportData);
  // console.log("updated dATA ",updateData);
  // console.log("mERGED dATA 12",mergedData);

  const [filteredData, setFilteredData] = useState(mergedData);

  const transformerGraphData = filteredData.map(item =>
    Object.fromEntries(
      Object.entries(item).filter(([key]) => columns2.includes(key))
    )
  );

  const lineGraphData = filteredData.map(item =>
    Object.fromEntries(
      Object.entries(item).filter(([key]) => columns3.includes(key))
    )
  );


  const countUniqueCapacities = (data) => {
    // Create a Map to hold the counts of each capacity
    const capacityCounts = new Map();

    // Iterate over the data
    data.forEach(item => {
      // Use xmr_capacity if it exists, otherwise use position
      const capacity = item["Capacity - KVA"];

      // Increment the count for this capacity/position in the Map
      if (capacityCounts.has(capacity)) {
        capacityCounts.set(capacity, capacityCounts.get(capacity) + 1);
      } else {
        // If it doesn't exist in the Map, add it with a count of 1
        capacityCounts.set(capacity, 1);
      }
    });

    // Convert the Map to an array of objects with name and size
    const result = Array.from(capacityCounts, ([capacity, size]) => ({
      "Capacity - KVA":capacity,
      "Numbers":size
    }));

    return result;
  };


  // const countUniqueCapacities = (data) => {
  //   // Create a Map to hold the sum of lengths for each capacity
  //   const capacitySums = new Map();
  
  //   // Iterate over the data
  //   data.forEach(item => {
  //     // Use xmr_capacity if it exists, otherwise use position
  //     const capacity = item.hasOwnProperty("xmr_capacity") ? item.xmr_capacity : item.position;
  //     const length = item.length || 0; // Default to 0 if length is not defined
  
  //     // Sum the length for this capacity/position in the Map
  //     if (capacitySums.has(capacity)) {
  //       capacitySums.set(capacity, capacitySums.get(capacity) + length);
  //     } else {
  //       // If it doesn't exist in the Map, add it with the current length
  //       capacitySums.set(capacity, length);
  //     }
  //   });
  
  //   // Convert the Map to an array of objects with capacity and sum of lengths
  //   const result = Array.from(capacitySums, ([capacity, sumLength]) => ({
  //     capacity,
  //     sumLength
  //   }));
  
  //   return result;
  // };
  


  // const countUniquePosition = (data) => {
  //   // Create a Map to hold the counts of each capacity
  //   const capacityCounts = new Map();

  //   // Iterate over the data
  //   data.forEach(item => {
  //     // Use xmr_capacity if it exists, otherwise use position
  //     const type = item.type;

  //     // Increment the count for this capacity/position in the Map
  //     if (capacityCounts.has(type)) {
  //       capacityCounts.set(type, capacityCounts.get(type) + 1);
  //     } else {
  //       // If it doesn't exist in the Map, add it with a count of 1
  //       capacityCounts.set(type, 1);
  //     }
  //   });

  //   // Convert the Map to an array of objects with position and size
  //   const result = Array.from(capacityCounts, ([type, size]) => ({
  //     type,
  //     size
  //   }));

  //   return result;
  // };
  const countUniquePosition = (data) => {
    // console.log("Position", data);
    // Create a Map to hold the sum of lengths for each type
    const typeSums = new Map();
  
    // Iterate over the data
    data.forEach(item => {
      const type = item["Line Type"];
      const lengthInMeters = item["Length (M)"] || 0; // Default to 0 if length is not defined
  
      // Convert length to kilometers
      const lengthInKm = lengthInMeters / 1000;
  
      // Sum the length for this type in the Map
      if (typeSums.has(type)) {
        typeSums.set(type, typeSums.get(type) + lengthInKm);
      } else {
        // If it doesn't exist in the Map, add it with the current length
        typeSums.set(type, lengthInKm);
      }
    });
  
    // Convert the Map to an array of objects with type and sum of lengths in kilometers
    const result = Array.from(typeSums, ([type, Length_of_Line]) => ({
      type,
      "Length of Line (km)": parseFloat(Length_of_Line.toFixed(2))
    }));
  
    return result;
  };
  


  const [transformerGraph, setTransformerGraph] = useState([]);
  const [lineGraph, setLineGraph] = useState([]);
  useEffect(() => {
    const capacitiesCount = countUniqueCapacities(filteredData);
    setTransformerGraph(capacitiesCount);
    // console.log("capacitiesCount",capacitiesCount);
  }, [filteredData]);

  useEffect(() => {
    const capacitiesCount = countUniquePosition(filteredData);
    setLineGraph(capacitiesCount);
    // console.log("capacitiesCount ---> 123 ---->> -->>",capacitiesCount);
  }, [filteredData]);



  const [isLoading, setIsLoading] = useState(false);

  const [selectedColumns, setSelectedColumns] = useState(mergedData.length > 0 ? Object.keys(mergedData[0]) : []);
  const [selectedCells, setSelectedCells] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [filterText, setFilterText] = useState('');

  const [result, setResult] = useState('');
  const [dataTablecolumns, setDataTablecolumns] = useState([]);
  const [outputData, setOutputData] = useState([]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  // const handleFilter = event => {


  //   const text = event.target.value;
  //   setDataTablecolumns(selectedColumns);

  //   setFilterText(text);
  //   setFilteredData(
  //     mergedData.filter(item =>
  //       dataTablecolumns.some(col => item[col]?.toString().toLowerCase().includes(text.toLowerCase()))
  //     )
  //   );


  // };
  const handleClearReport = () => {

    setFilteredData([]);
    setSelectedColumns([]);
    setResult('');
    
  }

  useEffect(()=>{
    setDataTablecolumns(selectedColumns);


  },[selectedColumns])

  const handleFilter = async (event) => {
    const text = event.target.value;
    setFilterText(text);
    // setDataTablecolumns(selectedColumns);
    setIsLoading(true); // Show loader

    try {
      // Simulate async filtering or fetching
      await new Promise(resolve => setTimeout(resolve, 500)); // Example delay

      // Replace with your actual filtering logic or API call
      setFilteredData(
        mergedData.filter(item =>
          dataTablecolumns.some(col => item[col]?.toString().toLowerCase().includes(text.toLowerCase()))
        )
      );
    } catch (error) {
      console.error('Error filtering data:', error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const handleMouseDown = (rowIndex, colName) => {
    setSelectedCells([[rowIndex, colName]]);
    setIsDragging(true);
  };

  const handleMouseEnter = (rowIndex, colName) => {
    if (isDragging) {
      setSelectedCells(prev => [...new Set([...prev, [rowIndex, colName]])]);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const isCellSelected = (rowIndex, colName) => {
    return selectedCells.some(([row, col]) => row === rowIndex && col === colName);
  };

  const handleExport = () => {
    const selectedData = selectedCells.map(([rowIndex, colName]) => ({
      rowIndex,
      colName,
      value: mergedData[rowIndex][colName],
    }));
    const ws = XLSX.utils.json_to_sheet(selectedData.map(({ value }) => ({ value })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SelectedCells');
    XLSX.writeFile(wb, 'selected_cells.xlsx');
  };

  const handleTableExport = () => {
    const filteredData = filterColumns(mergedData, selectedColumns.map(col => col.toLowerCase()));
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Table');
    XLSX.writeFile(wb, 'Table.xlsx');
  };

  const handleAverage = () => {
    const uniqueSelectedCells = selectedCells.filter(
      (cell, index, self) =>
        index === self.findIndex(
          ([rIndex, cName]) => rIndex === cell[0] && cName === cell[1]
        )
    );

    const values = uniqueSelectedCells.map(([rowIndex, colName]) => filteredData[rowIndex][colName]);
    const numericValues = values.filter(value => typeof value === 'number');
    const average = numericValues.length > 0
      ? numericValues.reduce((acc, value) => acc + value, 0) / numericValues.length
      : 0;
    // setResult(`Average: ${average.toFixed(2)}`);


    // console.log("average.toFixed(2)", typeof values[0]);

    if (typeof values[0] === 'number') {

      setResult(`Average: ${average.toFixed(2)}`);
      setError(false);

    } else {
      setResult(`Error: Non-numeric data detected. Please ensure all selected value are numeric`);
      setError(true);
    }


  };

  const handleSum = () => {
    const uniqueSelectedCells = selectedCells.filter(
      (cell, index, self) =>
        index === self.findIndex(
          ([rIndex, cName]) => rIndex === cell[0] && cName === cell[1]
        )
    );

    const values = uniqueSelectedCells.map(([rowIndex, colName]) => filteredData[rowIndex][colName]);
    const numericValues = values.filter(value => typeof value === 'number');
    const sum = numericValues.reduce((acc, value) => acc + value, 0);

    if (typeof values[0] === 'number') {

      setResult(`Sum: ${sum.toFixed(2)}`);
      setError(false);

    } else {
      setResult(`Error: Non-numeric data detected. Please ensure all selected value are numeric`);
      setError(true);
    }
  };

  const filterColumns = (data, selectedColumns) => {
    return data.map(item => {
      const filteredItem = {};
      selectedColumns.forEach(col => {
        filteredItem[col] = item[col];
      });
      return filteredItem;
    });
  };

  // useEffect(()=>{
  //   if(filteredData.length > 0){
  //     setLoading(false);
  //   }
  // },[filteredData]);
  const columns = dataTablecolumns.map(col => ({
    header: col.toLowerCase() === 'length' ? 'Length (m)' : col.charAt(0).toUpperCase() + col.slice(1),
    accessorKey: col,
    Cell: ({ row }) => (
      <div
        onMouseDown={() => handleMouseDown(row.index, col)}
        onMouseEnter={() => handleMouseEnter(row.index, col)}
        style={{
          backgroundColor: isCellSelected(row.index, col) ? '#b0d4ff' : '#fff',
          padding: '8px',
          border: 'none',
        }}
      >
        {row.getValue(col)}
      </div>
    ),
  }));
  
  // const[showColumns, setShowColumns] = useState(columns);


  const groupByAgeCityWise = (data) => {
    const groupedData = {};

    data.forEach(item => {
      const { age, city } = item;
      if (!groupedData[city]) {
        groupedData[city] = {};
      }
      if (!groupedData[city][age]) {
        groupedData[city][age] = [];
      }
      groupedData[city][age].push(item);
    });

    return groupedData;
  };

  const sumDataCityWise = (data, selectedOption, valueOption) => {
    const summedData = {};



    data.forEach(item => {
      const city = item[selectedOption];
      const value = item[valueOption];

      if (!summedData[city]) {
        summedData[city] = {
          [selectedOption]: city,
          [`sum_of_${valueOption}`]: 0
        };
      }

      summedData[city][`sum_of_${valueOption}`] += parseInt(value);
    });

    return Object.values(summedData);
  };

  const columnwiseFilter = (value1, value2) => {

    if(filteredData.length > 0 ){
    // if(type ofvalue2 )
    // const isNumeric = {typeof filterData[value2][0] == number ? true : false;
    const isNumeric = typeof mergedData[0][value2] === 'number';


    // console.log("isNumeric",value2 + isNumeric)
    // console.log("Data", typeof filteredData[0][value2]);
    // console.log("Data2", filteredData[0][value2]);

    if(isNumeric){
    
      const data = [value1, value2];
    
      const filteredData = filterColumns(mergedData, data.map(col => col));
    // console.log("Columns and FILTER DATA",filteredData);



    const newData = sumDataCityWise(filteredData, value1, value2);

    const column = Object.keys(newData[0]);
    // console.log(newData);
    setDataTablecolumns(column);
    // setSelectedColumns()
    setFilteredData(newData);
    
    setResult('');
    setError(false);

    }else{
      // setSelectedColumns([]);
      setDataTablecolumns([]);
      setResult(`Error: Non-numeric data detected. Please ensure all selected value are numeric`);
      setError(true);
    }

  }else{
  setResult(`No data available in the table`);
  setError(true);


  }

    
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);


  const [filterValues, setFilterValues] = useState({}); // State to store filter values
  // const [data, setData] = useState(initialData); // State to store table data

  // Handle column filter changes
  // const handleColumnFilterChange = (value) => {
  // console.log("<<<<<<<<<   Filter Values   >>>>>>>>", value);

  // setFilterValues(prevFilterValues => ({
  //   ...prevFilterValues,
  //   [columnId]: value,
  // }));
  // };
  // const [columnFilters, setColumnFilters] = useState({});





  // const filteredData1 = filteredData.filter(row => {
  //   return Object.keys(filterValues).every(key => {
  //     const filterValue = filterValues[key];
  //     if (!filterValue) return true; // No filter value means no filtering
  //     const cellValue = row[key];
  //     return cellValue?.toString().toLowerCase().includes(filterValue.toLowerCase());
  //   });
  // });

  //   useEffect(( )=>{ 

  // console.log("<<<<<<<<<   Filter Values   >>>>>>>>", filterValues);

  //   },[filterValues,setFilterValues])

  const handleGlobalFilterChange = (processValue, val2) => {
    // console.log("ProcessData", processValue, "2nd Val", val2);


  };


  const [columnFilters, setColumnFilters] = useState([]);

  // Handle column filter change
  const handleColumnFilterChange = (updater) => {
    setColumnFilters((old) => {
      // Ensure old is an array or object as expected
      if (!old || typeof old !== 'object') {
        console.error('Old filters state is not an array or object:', old);
        return [];
      }

      // Apply the functional update
      const updatedFilters = functionalUpdate(updater, old);

      if (!updatedFilters || !Array.isArray(updatedFilters)) {
        console.error('Updated filters are not an array:', updatedFilters);
        return [];
      }

      // Filter the updated filters based on your logic
      const newFilteredData = filterData(updatedFilters);
      setFilteredData(newFilteredData);

      return updatedFilters;
    });
  };

  // Custom filter data function
  const filterData = (filters) => {
    // console.log('Applying filters:', filters);

    const filteredData1 = mergedData.filter((row) => {
      return filters.every((filter) => {
        const { id: columnId, value: filterValue } = filter;
        const cellValue = row[columnId];

        // console.warn("Filter Value:", filterValue, " : ", cellValue);

        return typeof cellValue === 'string'
          ? cellValue.toLowerCase().includes(filterValue.toLowerCase())
          : cellValue == filterValue;
      });
    });

    // console.log('Filtered data:', filteredData1);
    setFilteredData(filteredData1);

    return filteredData1;
  };

  // Function to handle functional updates (if needed)
  const functionalUpdate = (updater, old) => {
    if (typeof updater === 'function') {
      return updater(old);
    }
    return updater;
  };

  const[consumerSummary,setConsumerSummary] = useState([]);

  useEffect(() => {
    // console.log("DATA Filter For Consumer Summary -> : ->>>: ", consumerData);
  
    const newData = filteredData.filter(data => 
      data['Consumer (NOS)'] != 0 || 
      data['Load (KW)'] != 0 || 
      data['PV (KW)'] != 0 || 
      data['PV Cons (NOS)'] != 0 
    );
  
    // console.error("NEW DATA Filter For Consumer Summary -> : ->>>: ", newData);
  
    // Extract location_id and project_id
    const extractedData = newData.map(data => ({
      location_id: data['Location ID'],
      project_id: data['Feeder Code']
    }));
  
    // console.log("Extracted Data -> : ->>>: ", extractedData);
  
    const filterConsumerData = consumerData.filter(consumer => 
      extractedData.some(item => 
        item.location_id === parseInt(consumer.location_id) && 
        item.project_id === parseInt(consumer.project_id)
      )
    );
  
    // const filterIds = filteredConsumerData
    // .filter(item => item.project_id)  // Filter out items with null/undefined project_id
    // .map(item => item.project_id);
    // console.error("Filtered filterIDS -> : ->>>: ", [...new Set(filterIds)]);
    // // console.error("Filtered Consumer Data -> : ->>>: ", filteredConsumerData);
  
    const filteredConsumerData = filterConsumerData.filter(item => item.category !== 'DTR');

    // console.error("Filtered Consumer Data new -> : ->>>: ", filteredConsumerData);


    const categoryMap = {
      A: 'Agriculture',
      E: 'EVS',
      G: 'GLP',
      H: 'HT',
      L: 'LTMD',
      N: 'NRGP',
      R: 'RGP',
      T: 'TEM',
      W: 'WW',
  
      // Add more mappings as needed
    };
  
    // Separate load, consumers, PV by category
    const dataByCategory = filteredConsumerData.reduce((acc, consumer) => {
      const category = consumer.category; // Assuming 'category' exists in consumer data
    
      // Map to new category name
      const firstLetter = category.charAt(0);
      const newCategory = categoryMap[firstLetter] || category;
    
      // Initialize the category if it doesn't exist
      if (!acc[newCategory]) {
        acc[newCategory] = {
          "Category": newCategory,
          'Consumer (NOS)': 0,
          'Load (KW)': 0,
          'PV (KW)': 0,
          'PV Cons (NOS)': 0
        };
      }
    
      // Sum consumers, load, PV, and PV Cons by category
      acc[newCategory]['Consumer (NOS)'] += parseInt(consumer.consumers) || 0;
      acc[newCategory]['Load (KW)'] += parseInt(consumer.load) || 0;
      acc[newCategory]['PV (KW)'] += parseInt(consumer.soalr_load) || 0;
      acc[newCategory]['PV Cons (NOS)'] += parseInt(consumer.solar_meters) || 0;
    
      return acc;
    }, {});
    
    // Convert the accumulator object to an array
    const dataByCategoryArray = Object.values(dataByCategory);
  
    setConsumerSummary(dataByCategoryArray);
    // console.log("Data by Category -> : ->>>: ", dataByCategoryArray);
  
  }, [filteredData, consumerData]);
  
  



  // console.log("type of",filteredData);
  // const textColor = typeof result === 'string' ? 'black' : 'black';

  // console.log("transformerGrapoh",transformerGraph);

  // console.log("DATA FETCH BY COLUMNS: ", columns);
  // console.log("DATA FETCH BY COLUMNS 1: ", selectedColumns);

  // console.log("DATA FETCH BY COLUMNS 1.5 -> : ->>>: ", updateData);
  // console.log("DATA FETCH BY COLUMNS 2 -> : ->>>: ", filteredData);

  // console.log("DATA FETCH BY COLUMNS 2: ", lineGraph);
  
  
  
  
  return (
    <>
    <div style={{ height: '100vh', width: '100vw', overflow: 'hidden', boxSizing: 'border-box' }}>

    {selectedColumns.includes("Capacity - KVA") && (
        <DraggableModal isOpen={isModalOpen} onClose={closeModal} title={"Transformer Summery"}>

          <TabbedTableDiagram data={transformerGraph} />
        </DraggableModal>
      )}

      {selectedColumns.includes("Length (M)") && (
        <DraggableModal isOpen={isModalOpen1} onClose={closeModal1} title={"Line Summery"}>
          <TabbedTableDiagram data={lineGraph} />
        </DraggableModal>)}
      

        {selectedColumns.includes("PV (KW)") && (
        <DraggableModal isOpen={isModalOpen2} onClose={closeModal2} title={"Consumer Summery"}>
          <ConsumerTabbedTable data={consumerSummary} />
        </DraggableModal>)}
      
    <TopNavbar />

    <Container maxWidth={false} disableGutters style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', padding: 0, background: '#faffff', boxSizing: 'border-box',  }}>
           
      <LeftSidebar
        handleClearReport={handleClearReport}
        columns={allcolumns}
        handleColumnChange={(col) => {
          setSelectedColumns(prev =>
            prev.includes(col) ? prev.filter(c => c !== col) : [...prev, col]
          );
        }}
        selectedColumns={selectedColumns}
        handleShowData={handleFilter}
        columnwiseFilter={columnwiseFilter}
        handleAverage={handleAverage}
        handleSum={handleSum}
        subMenuSection={subMenuSection}
        setSubMenuSection={setSubMenuSection}
        menuSection={menuSection}
        setMenuSection={setMenuSection}
        isData={`${filteredData.length > 0 ? true : false}`}
      />

      <div style={{
        width: menuSection !== null ? '80vw' : '95vw',
        transition: 'width .2s',
      
        flex: 1 // Ensures the content area grows to fill available space
      }}>
        {subMenuSection === "reports" && (
          <Paper elevation={3} style={{ background: ':#95cdeb', width: '100%', overflow: 'hidden',  }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '16px' }} className="px-2 shadow p-2 bg-white">
              <Typography variant="h4" gutterBottom>
                Reports
              </Typography>
              <div>
              {selectedColumns.includes("PV (KW)") && (
      
      
      <Button variant="outlined"  onClick={openModal2} style={{ marginRight: '8px', height: '3.5rem' }} className='mainBtn shadow'>Consumer Summary</Button>
      )}
                {selectedColumns.includes("Capacity - KVA") && (
                  <Button variant="outlined"  onClick={openModal} style={{ marginRight: '8px', height: '3.5rem' }} className='mainBtn shadow'>Transformer Summary</Button>
                )}
                {selectedColumns.includes("Line Type") && selectedColumns.includes("Length (M)") && (
                  <Button variant="outlined" onClick={openModal1} style={{ marginRight: '8px', height: '3.5rem' }} className='mainBtn shadow'>Line Summary</Button>
                )}
                <Button variant="outlined" onClick={handleExport} style={{ marginRight: '8px', height: '3.5rem' } } className='mainBtn shadow'>Export Selected</Button>
                <Button variant="outlined" onClick={handleTableExport} style={{ marginRight: '8px', height: '3.5rem' }} className='mainBtn shadow'>Export Table</Button>
              </div>
            </div>
            <Typography variant="body1" style={{ paddingLeft: '16px', marginTop: '16px', color: error ? 'red' : 'black' }}>{result}</Typography>
            <div style={{ marginTop: '16px', maxHeight: '100vh', width: '100%' }}>
              <div style={{
                width: '100%',
                height: '100vh',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                background:'#fff'
               
              }} className="p-1">
                {isLoading ? (
                 
                 <div style={{ position: 'relative', width: '100%'  }}>
                 <LinearProgress color="primary" />
                
               </div>                
                
                ) : (
                  <MaterialReactTable
                    columns={columns}
                    data={filteredData}
                    enableColumnResizing={false}
                    enableFullScreenToggle={false}
                    enableColumnOrdering
                    enableStickyHeader
                    enableStickyFooter
                    enableRowNumbers
                    onColumnFiltersChange={handleColumnFilterChange}
                    enableClickToCopy
                 
                    muiTableHeadCellProps={{
                      style: {
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        fontWeight: 'bold',
                        backgroundColor: '#E8E8E8',
                        // backgroundColor: '#f5f5f5',
                        borderBottom: '2px solid #ddd',
                        padding: '8px',
                      },
                    }}
                    muiTableBodyCellProps={({ row, column }) => ({
                      onMouseDown: () => handleMouseDown(row.index, column.id),
                      onMouseEnter: () => handleMouseEnter(row.index, column.id),
                      onMouseUp: handleMouseUp,
                      'data-column': column.id,
                      style: {
                        backgroundColor: isCellSelected(row.index, column.id) ? '#b0d4ff' : '#fff',
                        transition: 'background-color 0.2s ease-in-out',
                        padding: '8px',
                        border: 'none',
                      },
                      muiCopyButtonProps: {
                        fullWidth: true,
                        startIcon: <ContentCopy />,
                        sx: { justifyContent: 'flex-start' },
                      },
                    })}
                    muiTableBodyRowProps={{
                      onMouseUp: handleMouseUp,
                      style: {
                        borderBottom: '1px solid #ddd',
                        backgroundColor: 'transparent',
                      },
                    }}
                    muiTableContainerProps={{
                      sx: {
                        maxHeight: '600px',
                        maxWidth: '100%',
                        overflow: 'auto',
                        width: '100%',
                        margin:'10px'
                      },
                    }}
                    muiSkeletonProps={{
                      animation: 'wave', // or 'pulse'
                      height: 100,
                      width: '100%',
                    }}
                  />
                )}
                <Typography variant="body2" style={{ marginTop: '16px', textAlign: 'left' }}>
                  Selected Cells: {selectedCells.length}
                </Typography>
              </div>
            </div>
          </Paper>
        )}
        {subMenuSection === 'dashboard' && (
          <Home />
        )}
        {subMenuSection === "organizationView" && (
          <OrganizationView />
          // <ExtraComponent/>
        )}
        {subMenuSection === "substationView" && (
          <SubstationView />
        )}
      </div>
    </Container>
  </div>
</>
  );
};

export default MainComponent;
